import React, { useContext, useEffect, useState } from "react";
import config, { getParameterByName, merchantCode } from "../util";
import { Store } from "../Store";
import Chip from "@mui/material/Chip";
import axios from "axios";
import Slider from "react-slick";

import { addToOrder, clearOrder, removeFromOrder } from "../actions";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  Slide,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Alert } from "@material-ui/lab";
import { useStyles } from "../styles";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import ReviewScreen from "./ReviewScreen";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function OrderScreen(props) {
  const styles = useStyles();
  const { state, dispatch } = useContext(Store);
  const { orderItems, itemsCount, totalPrice, taxPrice, orderType } =
    state.order;
  const selectedCurrency = state.selectedCurrency;
  const [totalProducts, setTotalProducts] = useState([]);
  const [totalAddons, setTotalAddons] = useState([]);
  const [itemGroup, setItemGrorp] = useState([]);
  const [filterItmGrp, setFilterItmGrp] = useState([]);
  const [itemGrpId, setItemGrpId] = useState("");
  const [addonsGroup, setAddonsGroup] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [errorProducts, setErrorProducts] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [addons, setAddons] = useState([]);
  const [subPro, setSubPro] = useState([]);
  const [isPaneOpen, setIsPaneOpen] = useState(false);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [categoryProductMap, setCategoryProductMap] = useState({});
  const baseURL = config.baseURL;

  let { setting } = state.userSetting;
  let { userInfo } = state.userData;
  console.log(setting, userInfo);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // if (userInfo) {
  //   let body = document.getElementsByTagName("body");
  //   body[0].style.backgroundImage = `url(${userInfo.sokBGImg})  !important`;
  //   body[0].style.backgroundPosition = "center  !important";
  //   body[0].style.backgroundSize = "cover  !important";
  //   body[0].style.backgroundRepeat = "no-repeat  !important";
  // }
  const [product, setProduct] = useState([]);
  console.log(product);

  const closeHandler = () => {
    setIsOpen(false);
  };



  useEffect(() => {
    console.log("---getting adons---");

    if (!totalAddons.length && userInfo) {
      axios
        .get(
          `https://api.menulive.in/api/clover/product-modifiers?merchantCode=${merchantCode}`
        )
        .then((res) => {
          let adon = res.data.map((ad) => {
            ad["quantity"] = 0;
            return ad;
          });

          setTotalAddons(adon);
        });
    }
    // if (!itemGroup.length) {
    //   axios
    //     .get(
    //       `${baseURL}/api/clover/products/itemGroups?merchantCode=${merchantCode}`
    //     )
    //     .then((res) => setItemGrorp(res.data));
    // }
    document.getElementById("footer").style.display = "none";
  }, []);
  console.log(addonsGroup);

  useEffect(() => {
    axios
      .get(`https://api.menulive.in/api/clover/products?merchantCode=${merchantCode}`)
      .then((res) => {
        const products = res.data;
        setTotalProducts(products);

        // Create mapping of category IDs to products
        const map = {};
        const categorySet = new Set();

        products.forEach((product) => {
          if (
            product.category &&
            product.category.elements &&
            product.category.elements.length > 0
          ) {
            const categoryId = product.category.elements[0].id;
            const categoryName = product.category.elements[0].name;

            if (!map[categoryId]) {
              map[categoryId] = {
                name: categoryName,
                products: [],
              };
            }
            map[categoryId].products.push(product);
            categorySet.add(categoryId);
          }
        });

        setCategoryProductMap(map);
        setLoadingProducts(false);
      })
      .catch((error) => {
        setLoadingProducts(false);
        setErrorProducts("Failed to load products");
      });
  }, [baseURL, merchantCode]);

  console.log(loadingProducts);

  const productClickHandler = (p) => {
    console.log(p);

    console.log(orderItems);
    let existingOrder = orderItems.filter((o) => o.id === p.id);
    console.log(existingOrder);
    setProduct(p);

    if (existingOrder.length) {
      setQuantity(existingOrder[0].quantity);
    } else {
      setQuantity(1);
    }

    if (p.modifierGroups) {
      let adGrp = p.modifierGroups.elements.length
        ? p.modifierGroups.elements
        : [];
      console.log(adGrp);
      setAddonsGroup(adGrp);
      let adItems = [];
      adGrp.filter((it) => {
        totalAddons.filter((ad) => {
          if (ad.category === it.id) {
            adItems.push(ad);
          }
        });
      });
      console.log(adItems);
      setAddons(adItems);
    } else if (p.add_ons) {
      console.log(p.add_ons.split(","));
      let adongrp = [];
      p.add_ons.split(",").map((i) => {
        addonsGroup.map((ad) => {
          if (i === ad.id) {
            adongrp.push(ad);
          }
        });
      });
      let adItms = [];
      adongrp.map((ag) => {
        totalProducts.map((pro) => {
          if (ag.id === pro.category) {
            console.log(pro);
            pro["quantity"] = 0;
            adItms.push(pro);
          }
        });
      });
      setAddons(adItms);
    }

    setIsOpen(true);
  };
  console.log(addons);
  const handleAddonClick = (addon) => {
    setSelectedAddons((prev) => {
      if (prev.includes(addon._id)) {
        return prev.filter((id) => id !== addon._id);
      } else {
        return [...prev, addon._id];
      }
    });
  };

  const addToOrderHandler = () => {
    product["sub_pro"] = JSON.stringify(subPro.map((li) => li));
    product["addons"] = selectedAddons.map((id) =>
      addons.find((addon) => addon._id === id)
    );

    addToOrder(dispatch, { ...product, quantity });

    console.log(product);
    let adons = totalAddons.map((ad) => {
      ad.quantity = 0;
      return ad;
    });

    setTotalAddons(adons);
    setAddons([]);
    setIsOpen(false);
    setQuantity(1);
    setSubPro([]);
    setSelectedAddons([]);
  };

  const cancelOrRemoveFromOrder = () => {
    let adons = totalAddons.map((ad) => {
      ad.quantity = 0;
      return ad;
    });

    setTotalAddons(adons);
    setAddons([]);
    setSubPro([]);
    removeFromOrder(dispatch, product);
    setIsOpen(false);
  };
  const previewOrderHandler = () => {
    props.history.push(`/select-payment?` + window.location.href.split("?")[1]);
  };
  console.log(addonsGroup);
  const imageOnErrorHandler = (event) => {
    event.currentTarget.src = "./images/blank.jpg";
  };

  const itemGroupHandle = (itmgrpId) => {
    setItemGrpId(itmgrpId);
  };

  let ItemsWithGrp = filterItmGrp.length ? filterItmGrp : totalProducts;
  //console.log(ItemsWithGrp);

  return (
    <Box
      className={styles.root}
      style={{ backgroundColor: state.selectedBgColor }}

    >
      <div
        style={{
          position: "fixed",
          top: "332px",
          right: "0%",
          cursor: "pointer",
          backgroundColor: "red",
          borderRadius: "10%",
          zIndex: "100",
          width: "55px",
          height: "45px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight:"5px",
          border:" 1px solid black"
        }}
      >
        <ShoppingBasketIcon
          height="170px"
          width="170px"
          onClick={() => setIsPaneOpen(true)}
          style={{ color: "#ffffff" }}
        />
        <span
          style={{
            color: "#fffe25",
            fontSize: "1.2em",
            position: "absolute",
            top: "-2px",
            left: "32px",
          }}
        >
          {itemsCount}
        </span>
      </div>
      <div style={{ display: "none" }} className={styles.promo}>
        <Slider {...settings} autoplay={true} autoplaySpeed={2000}>
          <div>
            <img src="./images/sld1.jpeg" className={styles.promoImg} />
          </div>
          <div>
            <img src="./images/sld2.jpeg" className={styles.promoImg} />
          </div>
          <div>
            <img src="./images/sld3.jpeg" className={styles.promoImg} />
          </div>
        </Slider>
      </div>
      <SlidingPane
        className="some-custom-class"
        overlayClassName="some-custom-overlay-class"
        isOpen={isPaneOpen}
        width="95%"
        onRequestClose={() => {
          setIsPaneOpen(false);
        }}
      >
        <div style={{ padding: "20px" }}>
          <ReviewScreen setIsPaneOpen={setIsPaneOpen} />
        </div>
      </SlidingPane>
      <Dialog
        onClose={closeHandler}
        aria-labelledby="max-width-dialog-title"
        open={isOpen}
        maxWidth={"50%"}
      >
        <DialogTitle className={styles.center}>Add {product.name}</DialogTitle>
        <Box style={{ display: "flex", flexDirection: "row" }}>
          <Box style={{ flex: "0 0 40%" }}>
            <Card
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardMedia
                component="img"
                alt={product.name}
                onError={imageOnErrorHandler}
                image={`https://cloverstatic.com/menu-assets/items/${product._id}.jpeg`}
                style={{ width: "100%", height: "auto", maxHeight: "500px", marginBottom: "20px", paddingLeft: "20px" }}
              />
            </Card>
          </Box>
          <Box style={{ flex: "0 0 60%", overflowY: "auto", maxHeight: "500px" }}>
            <Box style={{ padding: "10px" }}>
              <Typography variant="h6">{product.name}</Typography>
              <Typography variant="h6">{selectedCurrency} {product.price}</Typography>
              <Box className={[styles.countRow, styles.center]} style={{ marginTop: "10px" }}>
                <Button
                  id="minus_btn"
                  variant="contained"
                 disabled={quantity === 1}
                  onClick={(e) => quantity > 1 && setQuantity(quantity - 1)}
                 style={{ marginRight: "10px", backgroundColor: "rgb(234,30,44)", color: "#ffffff" }}
                >
                  <RemoveIcon />
                </Button>
                <TextField
                  inputProps={{ className: styles.largeInput }}
                  InputProps={{
                    bar: true,
                    inputProps: {
                      className: styles.largeInput,
                    },
                  }}
                  className="largeNumber"
                  type="number"
                  variant="filled"
                  min={1}
                  value={quantity}
                />
                <Button
                  id="plus_btn"
                  variant="contained"
                  onClick={(e) => setQuantity(quantity + 1)}
                  style={{ marginLeft: "10px", backgroundColor: "rgb(234,30,44)", color: "#ffffff" }}
                >
                  <AddIcon sx={{ fontSize: "1px" }} />
                </Button>
              </Box>
            </Box>
            <Box style={{ margin: "10px" }}>
              {addonsGroup.length
                ? addonsGroup.map((addGroup) => (
                  <Box key={addGroup.id}>
                    <h3>{addGroup.name.toUpperCase()}</h3>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(3, 1fr)",
                        gap: "10px",
                      }}
                    >
                      {addons
                        .filter((addon) =>
                          addGroup.modifierIds.split(",").includes(addon._id)
                        )
                        .map((itm) => (
                          <Card
                            className={`addon-card ${selectedAddons.includes(itm._id)
                              ? "selected"
                              : ""
                              }`}
                            key={itm._id}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100px",
                              width: "200px",
                              border: "black 1px solid",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: selectedAddons.includes(
                                itm._id
                              )
                                ? "rgba(255, 184, 0, 0.57) !important"
                                : "transparent !important",
                              cursor: "pointer",
                            }}
                            onClick={() => handleAddonClick(itm)}
                          >
                            <CardContent
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span>
                                <strong>{itm.name}</strong>
                              </span>
                              <span>
                                {selectedCurrency} {itm.price}
                              </span>
                            </CardContent>
                          </Card>
                        ))}
                    </div>
                  </Box>
                ))
                : ""}
            </Box>
            <Box
              className={[styles.row, styles.around]}
              style={{ marginBottom: "20px" }}
            >
              <Button
                onClick={cancelOrRemoveFromOrder}
                variant="outlined"
                color="primary"
                size="large"
              >
                {orderItems.find((x) => x.name === product.name)
                  ? "Remove"
                  : "Cancel"}
              </Button>
              <Button
                onClick={addToOrderHandler}
                variant="contained"
                size="large"
                style={{ backgroundColor: "rgb(234,30,44)", color: "#ffffff" }}
              >
                ADD
              </Button>

            </Box>
          </Box>
        </Box>
      </Dialog>


      <Grid container>
        {Object.keys(categoryProductMap).map((categoryId) => {
          const { name, products } = categoryProductMap[categoryId];

          return (
            <React.Fragment key={categoryId}>
              <h3>{name}</h3>
              <Grid container spacing={3} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {loadingProducts ? (
                  <CircularProgress />
                ) : errorProducts ? (
                  <Alert severity="error">{errorProducts}</Alert>
                ) : (
                  products.map((it) => (
                    <React.Fragment key={it._id}>
                      <Slide direction="up" in={true}>
                        <Grid item xs={6}>
                          <Card
                            onClick={() =>
                              it.userId
                                ? productClickHandler(it)
                                : itemGroupHandle(it._id)
                            }
                            style={{
                              border: "1px solid #ccc",
                              padding: "10px",
                            }}
                          >
                            <CardActionArea>
                              <CardContent>
                                <Grid container spacing={2}>
                                  <Grid item xs={4}>
                                    <CardMedia
                                      component="img"
                                      alt={it.name}
                                      onError={imageOnErrorHandler}
                                      image={`https://cloverstatic.com/menu-assets/items/${it._id}.jpeg`}
                                      style={{
                                        width: "100%",
                                        height: "100px",
                                        borderRadius: "10px",
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={8}
                                    container
                                    direction="column"
                                    justifyContent="space-between"
                                  >
                                    <Box
                                      display="flex"
                                      flexDirection="column"
                                      justifyContent="space-between"
                                      style={{ paddingLeft: 0 }}
                                    >
                                      <Box display="flex" flexDirection="column" justifyContent="space-between">
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                          <Typography
                                            gutterBottom
                                            variant="h6"
                                            color="primary"
                                            component="p"
                                            style={{ lineHeight: 1.5, fontSize: "12px", marginLeft: 0,textAlign:'left',maxWidth:'80%' }}
                                          >
                                            {it.name}
                                          </Typography>
                                          {it.price && (
                                            <Typography
                                              variant="h6"
                                              style={{ fontSize: "14px", color: "black" }}
                                            >
                                              {`${selectedCurrency}${it.price}`}
                                            </Typography>
                                          )}
                                        </Box>
                                        <Box
                                          borderBottom="1px dotted #ccc"
                                          marginTop="8px"
                                          marginBottom="8px"
                                        />
                                        {it.price && (
                                          <Box display="flex" justifyContent="center" style={{border:'1px solid red',width:'64px',textAlign:'center',padding:'5px',position:'absolute',bottom:'10px',right:'10px'}}>
                                            <AddIcon
                                              style={{ color: "#EA1E2C", cursor: "pointer" }}
                                              onClick={(e) => {
                                                e.stopPropagation(); // Prevent the click from propagating to the Card
                                                productClickHandler(it);
                                              }}
                                            />
                                          </Box>
                                        )}
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      </Slide>
                    </React.Fragment>
                  ))
                )}
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>





      <Box
        style={{
          display: "none!important",
          position: "fixed",
          bottom: "0px",
          width: "96%",
        }}
        className="orderscreen_foot"
      >
        <Box>
          <Box
            className={[styles.bordered, styles.space]}
            style={{
              textAlign: "center",
              fontSize: "20px",
              lineHeight: "20px",
              display: "none",
            }}
          >
            {/*My Order - {orderType} |*/} Tax: {selectedCurrency}
            {taxPrice} | Total: {selectedCurrency}
            {totalPrice} | Items: {itemsCount}
          </Box>
          <Box
            id="footer"
            style={{ display: "none!important" }}
            className={[styles.row, styles.around, styles.space]}
          >
            <Button
              onClick={() => {
                clearOrder(dispatch);
                // props.history.push('/choose?' + window.location.href.split('?')[1]);
              }}
              variant="outlined"
              style={{ display: "none!important" }}
              className={styles.largeButton}
              id="choose_clbtn"
            >
              Remove
            </Button>

            <Button
              onClick={previewOrderHandler}
              variant="contained"
              color="primary"
              style={{ display: "none!important" }}
              disabled={orderItems.length === 0}
              className={styles.largeButton}
              id="nextbtn"
            >
              NEXT
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
