
import React, { useContext, useEffect,useState } from 'react';
import Axios from 'axios';
import { Box, Button, CardMedia, CircularProgress, Typography, Dialog,
  DialogTitle, } from '@material-ui/core';
import { useStyles } from '../styles';
import Logo from '../components/Logo';
import { Store } from '../Store';
import QRCode from "react-qr-code";
import {
  clearOrder,

} from '../actions';
import { setPaymentType, getStripePay} from '../actions';
import config,{ getParameterByName,merchantCode} from '../util';


export default function CompleteOrderScreen(props) {
 const baseURL = config.baseURL
  const payURL = "https://pay.digitallive24.com";
  const { state, dispatch } = useContext(Store);
  const { selectedCurrency, order, newQr, fetchQr,newURL } = state;
  let paytimer = null;
  console.log(newQr);
  const styles = useStyles();
  const [openForm,setOpenForm] = useState(false);
  const [openSign,setOpenSign] = useState(false);
  const [isPickUp,setIsPickUp] = useState(false);
  const [isDeliver,setIsDeliver] =useState(false);
  const [userName,setUserName] = useState("");
  const [number,setNumber] =useState("");
  const [schedule,setSchedule]= useState("");
  const [address,setAddress] =useState("");
  let { setting } = state.userSetting;
  const customersInfo =sessionStorage.getItem("customerInfo")?JSON.parse(sessionStorage.getItem("customerInfo")).user:"";
  console.log(customersInfo);

    let {userInfo}=state.userData;
console.log(userInfo);

  const getCurrency = userInfo ? userInfo.currency : "";
  const getPercent = setting ? setting.taxPercent : "";

  useEffect(() => {
    console.log(order);
    if (order && order.orderItems.length ) {
        let strpItems = [];
        //*************STRIPE ITEMS**********
        // order.orderItems.forEach(o => {
        //   strpItems.push({
        //     price_data:{
        //       currency:getCurrency,
        //       unit_amount:o.price * 100 + (o.price * getPercent / 100) * 100,
        //       product_data:{
        //         name: o.name 
        //       }
        //     },
        //     quantity:o.quantity

        //   });
        // });

        //*************CLOVER ITEMS**********
        order.orderItems.forEach(o => {
          strpItems.push({
            name: o.name,
            price:  o.price * 100 + (o.price * getPercent / 100) * 100,
            unitQty:o.quantity
          });
        });

       let sucUrl=(window.location != window.parent.location)?document.referrer+'/order_now': window.location.origin+'/complete';;
       let canUrl=(window.location != window.parent.location)?document.referrer+'/cancel': window.location.origin+'/cancel';;
       //getStripePay(dispatch,{...strpItems},sucUrl,canUrl)
       //getStripeCheckout(sucUrl, canUrl, strpItems);

       //clover payload
       let clvrPL = {
        "customer":{
          "firstName":customersInfo.firstName,
           "lastName":customersInfo.lastName,
           "email":customersInfo.email
        },
       "redirectUrls":{
          "success": sucUrl,
          "failue":canUrl,
           "cancel":canUrl
       },
       "shoppingCart":
       {"lineItems":strpItems}
     };
       console.log('sending for hosted checkout', clvrPL);
       getHostedClvrCheckout(clvrPL);

    }
  }, []);

  const getHostedClvrCheckout = async (clvrPL) => {
    try { 
      let subPath = "/api/clover/ecom/philip/checkout-url?merchantCode="+merchantCode;
      const { data } = await Axios.post(`${baseURL}${subPath}`,clvrPL);
      console.log('stripe host url', data);
      if (data  && data.href) {
        if (paytimer) clearInterval(paytimer);
        setTimeout(function(){
          if(window.location != window.parent.location){
           window.parent.location.href =data.href;
         }else{
           window.location.href =data.href;
         }
          
        },5*1000);
       
      }

    }
    catch (error) {
      console.log(error);

    }
  };

  const getStripeCheckout = async (sucUrl,canUrl,items) => {
    try { 
      let subPath = "/api/clover/ecom/philip/checkout-url?sucUrl="+sucUrl+"&canUrl="+canUrl;
      const { data } = await Axios.post(`${baseURL}${subPath}`,{line_items:items});
      console.log('stripe host url', data);
      if (data  && data.strpUrl) {
        if (paytimer) clearInterval(paytimer);
        setTimeout(function(){
          if(window.location != window.parent.location){
           window.parent.location.href =data.strpUrl;
         }else{
           window.location.href =data.strpUrl;
         }
          
        },5*1000);
       
      }

    }
    catch (error) {
      console.log(error);

    }
  };

  const handlePickUp =()=>{
      // setIsPickUp(true);
      // setOpenForm(true);
      setOpenSign(false);
      // setOrderType(dispatch,"Pick Up");
    }
    const handleDelivery =()=>{
      setOpenSign(false);
      // setIsDeliver(true);
      // setOpenForm(true);
      // setOrderType(dispatch,"Delivery");
    }

console.log(newURL);
  return (
    <>
      <Box className={[styles.root, styles.navy]} style={{ backgroundColor: state.selectedBgColor }}>
       <h1 style={{color:"red"}}>Don't close your window or refresh until order placing process!!</h1>
      </Box>
    </>
  );
}
