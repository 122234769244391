import { makeStyles } from '@material-ui/core';



export const useStyles = makeStyles((theme) => ({

	
	// Common
	root: {
		// backgroundImage: 'url(/images/bg-1.jpeg)',
		// backgroundRepeat: 'repeat',
		// backgroundPosition: 'center',
		fontFamily: 'Acme !important',
		padding: '40px',
		// backgroundSize:'cover',

		// width:"100%",
		height: '90vh',
		display: 'flex',
		flexDirection: 'column',
	},
	root1: {
		// backgroundImage: 'url(/images/bg-1.jpeg)',
		// backgroundRepeat: 'repeat',
		// backgroundPosition: 'center',
		// backgroundSize:'cover',

		// width:"100%",
		height: '100vh',
		display: 'flex',
		flexDirection: 'column',
		margin: 0,
	},
	center: {
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center',
		
	},
	column: { flexDirection: 'column' },
	main: {
		flex: 1,
		flexDirection: 'column',
		display: 'flex',
		// color: '#000000',
		textAlign: 'center',
		border: 3,
		
	},
	timer: {
		fontSize: '30px',
		color: '#e51111',
		position: 'absolute',
		width: '100%',
		textAlign: 'center',
	},
	timerSize: {
		margin: '5px',
		color: 'red',
		fontWeight: '10px',
	},

	orderAgainBtn: {

		borderRadius: '50px',
		width: '300px',
		height: '45px',
		fontSize: 30,

	},
	main1: {
		// flex: 1,
		overflow: 'auto',
		overflowX: 'hidden',
		// flexDirection: 'column',
		display: 'flex',
		color: '#000000',
		textAlign: 'center',
		border: 3,
		// marginBottom:"0px",
	},
	// navy: {
	// 	backgroundColor: '#003080',
	// },
	green: {
		backgroundColor: '#ff04044d',
	},
	footer: {
		// border:"2px solid #00e6f1 ",
		borderRadius: '50px',
		fontSize: "60px",


	},
	// choose screen
	cards: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		


	},

	// order screen
	// red: {
	// 	backgroundColor: '#ff2040',
	// 	color: '#ffffff',
	// },
	bordered: {
		width: "99%",
		backgroundColor: "#454242",
		borderWidth: 2,
		borderRadius: 5,
		color: 'white',
		margin: 5,
		borderStyle: 'Dash',
		fontSize: "20px",
		display:"grid",
		placeItem:"center"
		// height:"20px"
		

	},
	row: {
		display: 'flex',
		padding:2
		

	},
	space: {
		padding: 10,
		// paddingBottom: 25
	},
	around: {
		justifyContent: 'space-around',
	},
	between: {
		justifyContent: 'space-between',
	},
	itemsCenter:{
		alignItems:"center",
	},
	
	content:{
		justifyContent: 'space-evenly',
	},
	largeBtnColor: {
		backgroundColor: "red",

	},
	largeButton: {
		border: "2px solid  ",
		borderRadius: '50px',
		width: '200px',
		height: '50px',
		fontSize: 20,
	},


	largeNumber: {
		margin: '35px',

	},
	editBtn: {
		border: "2px solid  ",
		borderRadius: '50px',
	},

	vegBtn: {
		// position:'absolute',
		// right:'40px',
		// top:13,

		color: '#0d4016',

	},

	veg_img: {
		position: 'absolute',
		top: 15,
		right: 10,
	},

	catTitleBox: {
		margin: 0,
		marginBottom:"-10px",
	},

	largeInput: {
		width: '60px!important',
		padding: '0!important',
		fontSize: '35px!important',
		textAlign: 'center!important',
		margin: '18px',

	},

	orderNum: {
		fontSize: '55px!important',
		color: "green",
	},

	logo: {
		height: 50,
	},
	largeLogo: {
		height: 100,
	},
	title: {
		marginTop: 5,
		fontSize: '1.2rem',
		fontWeight: 400,
		// backgroundColor:'#d9c7c7',
		color: "#911818",

	},

	title3: {
		// color: '#8d241e',
		fontSize: "25px",
		
	},
	order: {
		display:"flex"
	},
	title1: {
		marginTop: 5,
		// color: "#0400f1",
	},
	title2: { color: "#c73737",marginBottom:"0px",fontSize:"50px" },
	card: {
		margin: 20,
		marginTop:-2,
		backgroundColor: "#fcf6f6b8",
		boxShadow: "0px 0px 4px black",
		backgroundColor: "#f8f8f8 !important",
		
	},
	media: { 
		width: "100%",
		 height: 130 ,
		
		},
	foot:{
		padding:"2px"
	},
	qr_img: {
		margin: 'auto',
		height: '100%',
		width: 'auto',
		padding: '20px',
	},
	qrCode: {

		height: '100%',
		width: 'auto'
	},
	qrTitle: {
		fontSize: '3em',
		textAlign: 'center',
		marginTop: '133px',
		marginBottom: '30px',
		background: '#ffffff52',
	},
	qrCodeGen: {
		height: "auto",
		margin: "0 auto",
		maxWidth: 500,
		padding: "10px",
		background: '#ffffff',
		width: "100%"
	},
	ready: {
		backgroundColor: '#003080',
		color: '#ffffff',
		marginTop: 0,
	},
	processing: {
		backgroundColor: '#404040',
		color: '#ffffff',
		marginTop: 0,
	},
	// Signin/Signup
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	promo:{
		width:"100%",
		height:"250px",
		padding: "20px",
		marginTop:"20px"

	},
	promoImg:{
		width:'100%',
		display:"inline-block",
		height:'200px',
		
		borderRadius:"10px",
		border:"5px solid #f5f5f5"
	},
	promoContainer: {
		display: 'inline-block',
		padding: '3px',	
		width: '100%',
		background: '#edecec61',
		marginBottom: '20px',
		height:"100%",
		marginTop:'20px'
		
	},
	categoryContainer: {
		display: 'inline-block',
		padding: '3px',	
		width: '100%',
		whiteSpace: 'nowrap',
		overflowX: 'auto',
		background: '#edecec61',
		marginBottom: -5,
		height:"100%",
		
	},
	
	categoryItem: {
		display: 'inline-block',
		// marginLeft: '22px',
		textAlign: 'center',
		fontSize: '15px',
		marginTop:"0px",
		
	},
	image: {

		width: '74px',
		height: '74px',
		borderRadius: '40px',
		cursor: "pointer",

	},
	imageHolder: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '78px',
		height: '78px',
		marginLeft: '22px',
		textAlign: 'center',
		fontSize: '15px',
		backgroundColor: '#fff',
		borderRadius: '50%',
		padding: '2px',
	},
	minus: {
		margin: '50px',
	},
	add: {
		margin: '50px',
	},
	token_sele: {
		float: 'right',
		marginRight: 80,
		marginTop: -30,
	},
	sendBtn: {

		border: "2px solid #00e6f1 ",
		borderRadius: '50px',
		display:"inline-block",
		padding:"0px 52px"
	},

	invoice: {
		backgroundColor: 'whitesmoke',
		borderRadius: '8px',
		display:"inline-block",
		overflowy:"scroll"
	},
	screen: {
		float: 'right',
		marginTop: '15px',
		marginBottom: "223px",
		width: "483px",

		borderRadius: '5px',
		fontSize: "30px"
	},

	editCard: {
		marginTop: '-5px',
	},

	cardFooter: {
		display: 'block',
		justifyContent: 'space-around',
		textAlign: 'left',
		padding:"0px",
		backgroundColor:"white",
		border: '1px solid #e7e4e4'
		
	},
	prod_title: {
		textOverflow: 'ellipsis',
		lineHeight:1,
		marginBottom:"0px",
		color: '#352a22',
		fontSize:'1.2em'

	},
	reviwText: {
		color: '#0c0c0d',
		
	},
	prod_cal: {
		color: '#352a22',
		
		fontSize:'0.8em'
	},
	prod_cal1: { color: '#352a22', },
	minus1:{
		height:35,
		width:60,
		borderRadius:'50px',
		margin:2,
		color:"white",
		background:"red",
		textAlign:"center",
		border:"none",
	},
	plus:{
		height:35,
		width:60,
		borderRadius:'50px',
		margin:2,
		color:"white",
		background:"green",
		textAlign:"center",
		border:"none",
	},
	btn_group:{
		width:"40%",
		display:"flex",
		justifyContent:"space-between",
		alignItems:"center",
		flexWrap:"nowrap",
	},
	addons:{
		width:"75%",
		display:"flex",
		justifyContent:"space-between",
		alignItems:"center",
		flexWrap:"nowrap",
	},

}));
