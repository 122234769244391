import React, { useContext, useEffect, useState } from 'react';
import { NumericKeyboard } from 'react-numeric-keyboard';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import Logo from '../components/Logo';
import { Store } from '../Store';
import { Alert } from '@material-ui/lab';
import { createOrder, setPaymentType, generateQrCode, clearOrder, getCheckoutUrl,getUserData } from '../actions';
import QRCode from "react-qr-code";
import axios from 'axios';
import HomeScreen from './SelectPaymentScreen';
import config,{ getParameterByName,merchantCode} from '../util';


export default function CompleteOrderScreen(props) {
  const styles = useStyles();
  const { state, dispatch } = useContext(Store);
  const { order, selectedCurrency } = state;
  const { loading, error, newOrder } = state.orderCreate;
  const [display, setDisplay] = useState(false);
  const [fontColor, setFontColor] = useState();
  const [displayOrder, setDisplayOrder] = useState(false);
  const [invData,setInvData]=useState();
  const [detailview,setDetailView] = useState(false);
  let {setting} = state.userSetting;
    console.log(setting);
let {userInfo}=state.userData;
console.log(userInfo);
  const [phnumber, setPhnumber] = useState("");
  console.log(phnumber)
  const getParameterByName = (e, t = window.location.href) => {
    e = e.replace(/[\[\]]/g, "\\$&"); var n = new RegExp("[?&]" + e + "(=([^&#]*)|&|#|$)").exec(t);
    return n ? n[2] ? decodeURIComponent(n[2].replace(/\+/g, " ")) : "" : null
  }
  let sokURL = window.location.href.indexOf('localhost') > 0 ?'https://sok.menulive.in':window.location.origin;
  // const isPaymentAllowed = getParameterByName("isPaymentAllowed");
  const baseURL = config.baseURL;
  const bgColor = getParameterByName("bgColor");
  const userCurrency =userInfo? userInfo.currency:"";
  const isScan = getParameterByName("isScan");
  const payURL="https://pay.digitallive24.com";

  const email =userInfo? userInfo.email:"";
  const upiId =userInfo? userInfo.upi:"";
  const upiName =userInfo? userInfo.customer:"";
  const gst =userInfo? userInfo.taxCodeNo:"";

   let isPaymentAllowed = setting?setting.isPaymentAllowed:"";
  console.log(isPaymentAllowed);

let paytimer = null;
 
  // useEffect(()=>getUserData(dispatch),[]);

  useEffect(() => {
      getInvNumber();
      console.log('sd');
    if (order.orderItems.length > 0) {
      let items = order.orderItems.map((o) => {
        o["status"] = "inProgress";
        return o;
      });
      console.log(items);
      order.orderItems = items;
      // order.paymentType = order.paymentType ? order.paymentType : "At Counter";
      order.paymentType = isPaymentAllowed ? order.paymentType : "At Counter";
      createOrder(dispatch, order);

    }

  }, [order]);



   if(newOrder){
        sessionStorage.setItem("newOrder",JSON.stringify(newOrder));
      }



  const getInvNumber=()=>{
     axios.get(`${payURL}/api/invoice/latest`).then((res) => setInvData(res.data));
  }


  const handleBack = () => {
    setDisplayOrder(false);
  }
  const handleForm = () => {
    setDisplay(!display);
  }
  const backSpaceImage = () => {
    return (
      <img src='/images/backspace-1.png' alt="backSpace" height="40vh" width="40vw" />
    )
  }

  let appUrl = window.location.href.replace("complete", "");
  let updateUrl = appUrl.replace(/&/g, "~");
  console.log(updateUrl);

   let orderDetails=JSON.parse(sessionStorage.getItem("newOrder"));
   console.log(orderDetails);

  let summaryPath1 =orderDetails ? `${sokURL}/app.html?serve_url=${baseURL}&orderId=${orderDetails ?orderDetails._id : ""}&currency=${userCurrency}&restaurant=${upiName}&cgst=${gst}&invoice=${invData&&invData.replace("dl","DL_")}&sok_url=${updateUrl}` : "";
  // console.log(`${sokURL}/app.html?serve_url=${baseURL}&orderId=${newOrder ? newOrder._id : ""}&currency=${userCurrency}&sok_url=${updateUrl}`);
console.log(encodeURI(summaryPath1));

    let paymentUrl =orderDetails?`upi://pay?pn=${upiName}&pa=${upiId}&am=${orderDetails.totalPrice}&tn=${orderDetails.number}`:"";
  
  const handleSubmit=()=>{
    let msgtext=`${encodeURI(summaryPath1)}`;
    axios.post(baseURL.replace("apps","cms")+"/api/send-sms-msg",{toMobile: `+91${phnumber}`, msgConent: msgtext})
    .then((res)=>{
     setPhnumber("");
    })
   }
  
  const handlePayment=(token,price)=>{

      // axios.post(`${baseURL}/menu/notifications`,
      //       {
      //         userId:setting.userId,
      //         msg:`Amount ${selectedCurrency}${price} received from token #${token}`,
      //         token:token,
      //       }
      //   ).then(res=>console.log(res.data));

        if (paytimer){ clearInterval(paytimer)};
        setDetailView(!detailview);
      
    }

    const handleCounter =()=>{
      if (paytimer){ clearInterval(paytimer)}
      setDetailView(true);
    }

 const orderStauts =()=>{
      let id =orderDetails._id
        axios.get(`${appUrl}/api/orders?merchantCode=${merchantCode}`)
        .then(res=>{
          if(res.data.isPaid){
             if (paytimer){ clearInterval(paytimer)}
            setDetailView(true);
          }
        })
    }
 
      function startTimer() {
        var duration = 60 * 10;
        var timer = duration, minutes, seconds;
        if (paytimer) clearInterval(paytimer);
        paytimer = setInterval(function () {


          let display = document.getElementById('time');
          minutes = parseInt(timer / 60, 10);
          seconds = parseInt(timer % 60, 10);

          minutes = minutes < 10 ? "0" + minutes : minutes;
          seconds = seconds < 10 ? "0" + seconds : seconds;
        

          if (--timer < 0) {
            timer = duration;
            if(paytimer) clearInterval(paytimer);
            console.log("Qr Expired");
          }

          if (seconds % 10 === 0) {
            console.log("hello");
            orderStauts();
          }
        }, 1000);

  }

if(orderDetails&&!isPaymentAllowed && !paytimer){
  // startTimer();
}


if(setting){
       setTimeout(() => {
        let textcolor = document.getElementById("title1");
        textcolor.style.color = setting.color_primary;
      }, 200);
   }


  return (
    <Box className={[styles.root1, styles.navy]} style={{ backgroundColor: state.selectedBgColor }}>
      <Box className={[styles.main1, styles.center]}>
        <Box style={{ marginTop: "30px", padding: "10px" }}>
          {/* <img src={state.selectedLogo} height='80px' width='100px' /> */}
          { !orderDetails && loading   ? (
            <CircularProgress></CircularProgress>
          ) : error ? (
            <Alert severity="error">{error}</Alert>
          ) : (
            <>
                <Typography
                  gutterBottom
                  variant="h3"
                  component="h3"
                  id="title1"
                  style={{fontSize:"25px" }}
                >
                 {detailview ||isPaymentAllowed?"Thank you! order has been placed":"Process your Payment"}
                </Typography>
              {/* <div className={styles.order}> */}

              {detailview ||isPaymentAllowed?  <Typography
                          gutterBottom
                          className={styles.title3}
                          // style={{padding:"50px 100px 0px 0px"}}
                          variant="h3"
                          component="h3"
                        >
                          Token #<span className={styles.orderNum}>{orderDetails.number}</span>
                        </Typography>:""}


                {/* <Typography
                  gutterBottom
                  style={{color:"white", fontSize:"30px"}}
                  variant="h3"
                  component="h3"
                >
                Payment here 
                <QRCode
                  size={456}
                  style={{ height: "150px", maxWidth: "150px", width: "150px", marginTop: "20px", paddingLeft:"20px" }}
                  value={encodeURI(summaryPath1)}
                  viewBox={`0 0 456 456`}
                />{ HomeScreen }
                </Typography> */}
              {/* </div> */}


              
              <h5 style={{ color: "black", fontWeight: "bold" }}>{detailview ||isPaymentAllowed?"To Get Order Summary":"Scan to Pay"}</h5>
           {  !displayOrder && false ? <div style={{  marginTop: "25px" }}>
               {!display ? <button className='btn btn-dark' onClick={handleForm}>Enter Your Mobile No.</button>:
               
               <Box style={{ display: 'flex' }} >
               <input type='tel' value={phnumber} name="phoneNumber" className='form-control p-3' />

               {phnumber?<Button variant="contained"
                 color="secondary"
 
                 onClick={handleSubmit}                  
                 >submit
               </Button>:
               <Button variant="outlined" color="primary"
                
                 onClick={()=>setDisplay(false)}                  
                 >cancle
               </Button>}
             </Box>
               
               }<br/><br/>
                <span style={{marginTop:"20px"}}>( or )</span>
              <div style={{marginTop:"20px"}}>
               <button onClick={()=>setDisplayOrder(true)} className='btn btn-dark'>Skip I Will Remember</button>

              </div>
              </div>:"" }
             
              {/* QRCODE */}
             { orderDetails && !isPaymentAllowed && !detailview ? <div>
                              <QRCode
                                size={456}
                                style={{ height: "150px", maxWidth: "150px", width: "150px", marginTop: "20px" }}
                                value={paymentUrl}
                                viewBox={`0 0 456 456`}
                                />
                              {isScan === "true" ? <div>
                                <div style={{ marginTop: "20px" }}>
                                  <a href={paymentUrl} target='_blank' style={{ fontSize: "18px", color: "blue", fontWeight: "bold" }}>Click to Pay</a>
                                </div>
                              </div> : " "}
                          <div style={{width:"400px",display:"flex",justifyContent:"space-evenly",alignItems:"center",margin:"20px"}}>
                          <button className="pay-btn" onClick={()=>handlePayment(orderDetails.number,orderDetails.totalPrice)}>Done</button>
                          <button className="pay-btn" style={{width:"135px",backgroundColor:"blue"}} onClick={handleCounter}>Pay At Counter</button>
                          </div>

                        </div>:""}

                {detailview ||isPaymentAllowed ? <div>
                    <QRCode
                      size={456}
                      style={{ height: "150px", maxWidth: "150px", width: "150px", marginTop: "20px" }}
                      value={encodeURI(summaryPath1)}
                      viewBox={`0 0 456 456`}
                      />
                    {false&&<h6 onClick={()=>setDisplayOrder(false)}>go back</h6>}
                    <h6 style={{fontWeight:"20px",padding:"12px",fontSize:"20px"}}>Scan (or) Take Snapshot </h6>
              </div>:"" }

              { isScan === "true" &&( detailview ||isPaymentAllowed) ? <div>
                <h6 style={{ marginTop: "15px" }}> ( OR )</h6>
                <div style={{ marginTop: "20px" }}>
                  <a href={encodeURI(summaryPath1)} target='_blank' style={{ fontSize: "18px", color: "blue", fontWeight: "bold" }}>Click Here</a>
                </div>
              </div> : " "}

              {/* <Box className={styles.invoice}>
                <Box style={{ overflowY: "scroll", height: "300px" }}>
                  <table cellSpacing='50px' cellPadding={"20px"} align='center' className='table' >
                    <thead>
                      <tr style={{ borderColor: "#0e0e0e" }}>
                        <th>Order Items</th>
                        <th>Quantity</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody style={{ borderColor: "#0e0e0e", overflow: "scroll" }}>
                      {
                        newOrder && newOrder.orderItems.map((item) => {
                          console.log(item.name, item.quantity);
                          return (
                            <>
                              <tr style={{ borderColor: "#0e0e0e" }}>
                                <td scope='col'>{item.name}</td>
                                <td>{item.quantity}</td>
                                <td>{selectedCurrency}{item.price}</td>
                              </tr>
                            </>
                          )
                        })
                      }
                      <tr>
                        <td colspan='3' align='end'> TAX : {selectedCurrency}{newOrder.taxPrice}</td>
                      </tr>
                    </tbody>
                  </table>
                </Box>
                <Typography
                  gutterBottom
                  // className={styles.title1}
                  variant="h5"
                  component="h5"
                  style={{ float: "right", marginRight: "8px" }}
                >
                  Total : {selectedCurrency}{newOrder.totalPrice}
                </Typography>
              </Box> <br /> */}

              {/* <Box className={[styles.sendBtn,]} >
                {!display ?

                  <Typography

                    variant="h2"
                    component="h2"
                    className='text-primary fw-bold'
                    onClick={handleForm}
                  >
                    Send this to me
                  </Typography>
                  :
                  <Typography
                    variant="h2"

                    component="h2"
                    className='text-danger fw-bold'
                    onClick={handleForm}
                  >
                    Close
                  </Typography>}
              </Box> */}


            </>
          )}

            <Box>

              <NumericKeyboard
                isOpen={display}
                backSpaceIcon={backSpaceImage()}
                onChange={({ value, name }) => setPhnumber(value)} />

            </Box>
        </Box>
      </Box>
     {detailview ||isPaymentAllowed ||!orderDetails? <div style={{ display: "grid", placeItems: "center", marginTop: "35px" }}>
        <Box  >
          <Button
            onClick={() => {
              if (paytimer) clearInterval(paytimer);
             sessionStorage.removeItem("newOrder");
              clearOrder(dispatch);
              props.history.push('/?' + window.location.href.split('?')[1]);
             
            }
            }
            variant="contained"
            color="primary"
            className={styles.orderAgainBtn}
          >
            Order Again
          </Button>
        </Box>
      </div>:""}


      {/* <div style={{ position: "absolute", bottom: "10px", left: "30%" }}>
        <Box  >
          <Button
            onClick={() => {
              clearOrder(dispatch);
              props.history.push('/?' + window.location.href.split('?')[1]);
            }
            }
            variant="contained"
            color="primary"
            className={styles.orderAgainBtn}
          >
            Order Again
          </Button>
        </Box>
      </div> */}
    </Box>
  );

}
