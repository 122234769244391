import React, { useContext, useEffect, useState } from 'react';
import { Store } from './Store';
import { BrowserRouter, Route } from 'react-router-dom';
import { ThemeProvider, CssBaseline, Container, Paper, Box, Button, Dialog } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { useStyles } from './styles';
import { getUserData, getUserSettings } from './actions';
import ChooseScreen from './screens/ChooseScreen';
import HomeScreen from './screens/HomeScreen';
import OrderScreen from './screens/OrderScreen';
import QueueScreen from './screens/QueueScreen';
import ReviewScreen from './screens/ReviewScreen';
import PaymentScreen from './screens/PaymentScreen';
import AdminScreen from './screens/AdminScreen';
import SelectPaymentScreen from './screens/SelectPaymentScreen';
import CompleteOrderScreen from './screens/CompleteOrderScreen';
import SignUp from './screens/SignUp';
import CL_ChooseScreen from './clover/ChooseScreen';
import CL_HomeScreen from './clover/HomeScreen';
import CL_OrderScreen from './clover/OrderScreen';
import CL_QueueScreen from './clover/QueueScreen';
import CL_ReviewScreen from './clover/ReviewScreen';
import CL_PaymentScreen from './clover/PaymentScreen';
import CL_AdminScreen from './clover/AdminScreen';
import CL_SelectPaymentScreen from './clover/SelectPaymentScreen';
import CL_CompleteOrderScreen from './clover/CompleteOrderScreen';
import CL_SignUp from './clover/SignUp';
import './App.css';
import config, { getParameterByName, merchantCode } from './util';

let themes = [
  {
    typography: {
      h1: { fontWeight: 'bold' },
      h2: { fontSize: '2rem', color: 'black' },
      h3: { fontSize: '1.8rem', fontWeight: 'bold', color: 'black' },
    },
    palette: {
      primary: { main: '#F3CC43' },
      secondary: { main: '#118e16', contrastText: '#ffffff' },
    },
  },
  {
    typography: {
      h1: { fontWeight: 'bold' },
      h2: { fontSize: '2rem', color: 'black' },
      h3: { fontSize: '1.8rem', fontWeight: 'bold', color: 'black' },
    },
    palette: {
      primary: { main: '#672b2b' },
      secondary: { main: '#118e16', contrastText: '#f4afaf' },
    },
  },
];

let themeInx = getParameterByName('theme')||sessionStorage.getItem('theme');
sessionStorage.setItem('theme',themeInx);
themeInx = themeInx&&themeInx.length ? parseInt(themeInx) : 0;
const theme = createTheme(themes[themeInx]);

function App({ log }) {
  const { state, dispatch } = useContext(Store);
  const styles = useStyles();
  const [signDialog, setSignDialog] = useState(false);
  const [guest, setGuest] = useState(false);
  let { userInfo } = state.userData;

  useEffect(() => {
    getUserData(dispatch);
  }, [dispatch]);

 
  const handleDialog = () => {
    setSignDialog(false);
    setGuest(true);
  };

  const handleLogout = () => {
    sessionStorage.clear("customerInfo");
    localStorage.removeItem('userInfo');
    setSignDialog(true);
  };

  let customerInfo = sessionStorage.getItem("customerInfo")
    ? JSON.parse(sessionStorage.getItem("customerInfo"))
    : null;
   
  let runningOrder =localStorage.getItem('runningOrder');

  console.log('user---', userInfo);
  if((userInfo && !userInfo.length) && localStorage.getItem('userInfo'))
  {
    userInfo = JSON.parse(localStorage.getItem('userInfo'));
  }
  return (
    <BrowserRouter>
      <Helmet>
        <title>Online Order</title>
      </Helmet>
      <Dialog
        aria-labelledby="max-width-dialog-title"
        open={signDialog}
        fullWidth={true}
        maxWidth={state.widthScreen ? 'sm' : 'xs'}
      >
        <div style={{ padding: "20px" }}>
        {userInfo && userInfo.posProviderName && userInfo.posProviderName.toUpperCase() === 'CLOVER' && 
          <CL_SignUp handleDialog={handleDialog} setSignDialog={setSignDialog} />
        }
         {userInfo && !userInfo.activeProviderId && <SignUp handleDialog={handleDialog} setSignDialog={setSignDialog} />}
        </div>
      </Dialog>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth={state.widthScreen ? 'xl' : 'sm'}>
          <Paper>
            <Box
              style={{
                marginBottom: 0,
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'right',
                height: "15vh",
              }}
            >
              {customerInfo && customerInfo.user ? (
                <span className='sign_btn'>
                  {customerInfo.user.firstName}
                  <Button
                    variant="contained"
                    style={{ marginLeft: "10px" }}
                    onClick={handleLogout}
                  >
                    logout
                  </Button>
                </span>
              ) : !guest ? (
                <button
                  onClick={() => setSignDialog(true)}
                  className='sign_btn'
                >
                  LOG IN
                </button>
              ) : (
                <Box>Welcome Guest!</Box>
              )}
            </Box>
            {userInfo && !userInfo.activeProviderId && (
              <div>
                <Route path="/home" component={HomeScreen} exact />
                <Route path="/admin" component={AdminScreen} exact />
                <Route path="/queue" component={QueueScreen} exact />
                <Route path="/choose" component={ChooseScreen} exact />
                <Route path="/" component={OrderScreen} exact />
                <Route path="/review" component={ReviewScreen} exact />
                <Route path="/select-payment" component={SelectPaymentScreen} exact />
                <Route path="/payment" component={PaymentScreen} exact />
                <Route path="/complete" component={CompleteOrderScreen} exact />
                <Route path="/signup" component={SignUp} exact />
              </div>
            )}
            {userInfo && userInfo.posProviderName && userInfo.posProviderName.toUpperCase() === 'CLOVER' && (
              <div>
                <Route path="/home" component={CL_HomeScreen} exact />
                <Route path="/admin" component={CL_AdminScreen} exact />
                <Route path="/queue" component={CL_QueueScreen} exact />
                <Route path="/choose" component={CL_ChooseScreen} exact />
                <Route path="/" component={runningOrder?CL_CompleteOrderScreen:CL_OrderScreen} exact />
                <Route path="/review" component={CL_ReviewScreen} exact />
                <Route path="/select-payment" component={CL_SelectPaymentScreen} exact />
                <Route path="/payment" component={CL_PaymentScreen} exact />
                <Route path="/complete" component={CL_CompleteOrderScreen} exact />
                <Route path="/signup" component={CL_SignUp} exact />
              </div>
            )}
          </Paper>
        </Container>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
