import React, { useContext, useEffect, useState } from 'react';
import { Store } from '../Store';
import { addToOrder, removeFromOrder } from '../actions';
import { setPaymentType, generateQrCode, getCheckoutUrl } from '../actions';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useStyles } from '../styles';
import App from "../App";
import SignUp from './SignUp';
import { useHistory } from "react-router-dom"; 


export default function ReviewScreen(props) {
  const styles = useStyles();
  const { state, dispatch } = useContext(Store);
  const { orderItems, itemsCount, totalPrice, taxPrice, orderType } = state.order;
  const selectedCurrency = state.selectedCurrency;
  
  let { setting } = state.userSetting;
  const [quantity, setQuantity] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [product, setProduct] = useState({});
  const [addons, setAddons] = useState([]);
  let loggedUser = sessionStorage.getItem("customerInfo");

  const [openSign, setOpenSign] = useState(loggedUser?true:false);
  const history = useHistory();
  let { userInfo } = state.userData;
  const getCurrency = userInfo ? userInfo.currency : "";
  const getPercent = setting ? setting.taxPercent : "";
  let items = [];
  let addonsItems = [];
  
  if (orderItems.length) {
    orderItems.forEach((o) => {
      items.push({
        currency: getCurrency,
        inventory_id: o._id,
        amount: o.price * 100 + (o.price * getPercent / 100) * 100
      });
      addonsItems = o.addons;
    });
  }

  const closeHandler = () => {
    setIsOpen(false);
  };



  const productClickHandler = (p) => {
    let existingOrder = orderItems.filter(o => o._id === p._id);
    setProduct(p);
    setAddons(JSON.parse(p.sub_pro));
    if (existingOrder.length) {
      setQuantity(existingOrder[0].quantity);
    } else {
      setQuantity(1);
    }
    setIsOpen(true);
  };

  const addToOrderHandler = () => {
    addToOrder(dispatch, { ...product, quantity });
    setIsOpen(false);
  };

  const cancelOrRemoveFromOrder = () => {
    removeFromOrder(dispatch, product);
    setIsOpen(false);
  };

  let metadata = {};
  items.forEach((item) => {
    metadata[item.inventory_id] = JSON.stringify(addonsItems);
  });

  const procedToCheckoutHandler = () => {
    
    let loginData = sessionStorage.getItem('customerInfo');
    loginData = JSON.parse(loginData);
    if (loginData !== null) {
      
      history.push("/select-payment?"+ window.location.href.split('?')[1]);
    } else {
      setOpenSign(true); 
    }
    //props.setIsPaneOpen(false);
  };


  const adAddons = (adId) => {
    let existingItem = product;
    let adOns = JSON.parse(existingItem.sub_pro).map((ad) => {
      if (ad._id === adId) {
        ad.quantity = ad.quantity + 1;
      }
      return ad;
    });
    setAddons(adOns);
    existingItem.sub_pro = JSON.stringify(adOns);
    setProduct(existingItem);
  };

  const removeAddons = (adId) => {
    let existingItem = product;
    let adOns = JSON.parse(existingItem.sub_pro).map((ad) => {
      if (ad._id === adId) {
        ad.quantity = ad.quantity - 1;
      }
      return ad;
    });
    setAddons(adOns);
    existingItem.sub_pro = JSON.stringify(adOns);
    setProduct(existingItem);
  };

  return (
    <Box className={[styles.root]}>
      <Box className={[styles.main, styles.center]} style={{ justifyContent: "start" }}>
        <Dialog
          onClose={closeHandler}
          aria-labelledby="max-width-dialog-title"
          open={isOpen}
          fullWidth={true}
          maxWidth={state.widthScreen ? 'sm' : 'xs'}
        >
          <DialogTitle className={styles.center}>
            Add {product.name}
          </DialogTitle>
          <Box className={[styles.row, styles.center]}>
            <Button className={styles.minus}
              variant="contained"
              color="primary"
              disabled={quantity === 1}
              onClick={(e) => quantity > 1 && setQuantity(quantity - 1)}
            >
              <RemoveIcon />
            </Button>
            <TextField
              inputProps={{ className: styles.largeInput }}
              className={styles.largeNumber}
              type="number"
              min={1}
              variant="filled"
              value={quantity}
            />
            <Button className={styles.add}
              variant="contained"
              color="primary"
              onClick={(e) => setQuantity(quantity + 1)}
            >
              <AddIcon />
            </Button>
          </Box>
          <Box style={{ margin: "10px" }}>
            <h5 style={{ textAlign: "center" }}>{addons.length >= 1 ? "Add-ons" : ""}</h5>
            {addons.length >= 1 ? addons.map(li => (
              <div style={{ display: "flex", justifyContent: "space-between", alignContent: "center", padding: "3px 15px", fontSize: "20px", flexWrap: "wrap" }}>
                {li.name} <div className={styles.btn_group}>
                  <span>{selectedCurrency} {li.price} </span>
                  <span className={styles.addons}>
                    <button className={styles.minus1} onClick={() => removeAddons(li._id)}><RemoveIcon /></button>
                    {li.quantity}
                    <button className={styles.plus} onClick={() => adAddons(li._id)}> <AddIcon sx={{ fontSize: "1px" }} /></button>
                  </span>
                </div>
              </div>
            ))
              : ""}
          </Box>
          <Box className={[styles.row, styles.around]} style={{ marginBottom: "20px" }}>
            <Button
              onClick={cancelOrRemoveFromOrder}
              variant="outlined"
              color="primary"
              size="large"
              className={[styles.largeButton, styles.card]}
            >
              {orderItems.find((x) => x.name === product.name) ? 'Remove' : 'Cancel'}
            </Button>
            <Button
              onClick={addToOrderHandler}
              variant="contained"
              color="primary"
              size="large"
              className={styles.largeButton}
            >
              ADD
            </Button>
          </Box>
        </Dialog>
        <Box className={[styles.center, styles.around]}>
          <Typography
            gutterBottom
            className={styles.title3}
            variant="h3"
            component="h3"
            id="title1"
          >
            Review my {orderType} order
          </Typography>
        </Box>
        <Box style={{ overflowY: "auto", height: "500px" }}>
          <Grid container>
            {orderItems.map((orderItem) => (
              <Grid item md={12} sm={12} xs={12} key={orderItem.name}>
                <Card
                  className={[styles.card, styles.editCard]}
                  onClick={() => productClickHandler(orderItem)}
                >
                  <CardActionArea>
                    <CardContent>
                      <Box className={[styles.row, styles.between, styles.itemsCenter]}>
                        <div>
                          <Typography
                            gutterBottom
                            variant="h6"
                            className={styles.reviwText}
                            component="p"
                          >
                            {orderItem.name}
                          </Typography>
                        </div>
                        <div className='amount' style={{ position: "absolute", left: "50%" }}>
                          <h3>{orderItem.quantity} x {selectedCurrency}{orderItem.price}</h3>
                        </div>
                        <div>
                          <Button variant="contained"  className={styles.editBtn} style={{ backgroundColor: "rgb(234,30,44)", color: "#ffffff" }}
                          >Edit</Button>
                        </div>
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Box style={{ position: "fixed", bottom: "15px", width: "92%" }}>
        <Box>
          <Box className={[styles.bordered, styles.space]} style={{ textAlign: 'center', fontSize: '20px', lineHeight: "20px" }}>
            Tax: {selectedCurrency}{taxPrice} | Total: {selectedCurrency}{totalPrice} | Items: {itemsCount}
          </Box>
          <Box className={[styles.row, styles.around]}>
            <Button
              onClick={() => {
                props.setIsPaneOpen(false)
              }}
              variant="outlined"
              color="primary"
              className={[styles.largeButton]}
            >
              Back
            </Button>
            <Button
              onClick={procedToCheckoutHandler}
              variant="contained"
              // color="primary"
              disabled={orderItems.length === 0}
              className={styles.largeButton}
              style={{ backgroundColor: "rgb(234,30,44)", color: "#ffffff" }}
            >
              Checkout
            </Button>
          </Box>
        </Box>
      </Box>
      <Dialog open={openSign} fullWidth maxWidth="sm">
  <SignUp log={openSign} setOpenSign={setOpenSign}/>
</Dialog>

    </Box>
  );
}
