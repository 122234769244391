import React, { useContext, useEffect, useState } from 'react';
import axios from "axios";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';
import { useStyles } from '../styles';
import Logo from '../components/Logo';
import { Button, CircularProgress, Dialog,
  DialogTitle, } from '@material-ui/core';
import { setPaymentType, generateQrCode, getCheckoutUrl,setOrderType,setCustomerId } from '../actions';
import { Store } from '../Store';
import config,{ getParameterByName,merchantCode} from '../util';

export default function HomeScreen(props) {
  const { state, dispatch } = useContext(Store);
  const styles = useStyles();
  const { selectedCurrency, order, qr } = state;

  const [userName,setUserName] = useState("");
  const [number,setNumber] =useState("");
  const [schedule,setSchedule]= useState("");
  const [address,setAddress] =useState("");
  let { setting } = state.userSetting;
  const [quantity, setQuantity] = useState(1);
  const [openForm,setOpenForm] = useState(false);
  const [openSign,setOpenSign] = useState(false);
  const [isPickUp,setIsPickUp] = useState(false);
  const [isDeliver,setIsDeliver] =useState(false);
  //let loggedUser = sessionStorage.getItem("customerInfo");
  let loggedUser =sessionStorage.getItem("customerInfo")?JSON.parse(sessionStorage.getItem("customerInfo")):null;

    let {userInfo}=state.userData;
console.log(userInfo);

  const getCurrency = userInfo ? userInfo.currency : "";
  const getPercent = setting ? setting.taxPercent : "";
useEffect(()=>{
  if(loggedUser&&loggedUser.length){
   
   setCustomerId(dispatch,loggedUser.user._id)
  }
},[])

 const baseURL =config.baseURL;
  const payURL = "https://pay.digitallive24.com";

  const selectHandler = (orderType) => {
    setOrderType(dispatch, orderType);
    setOpenForm(true);
    setIsPickUp(true);
      // if (order.totalPrice) {
      //       getCheckoutUrl(dispatch, {
      //         items: items,
      //         success_url: window.location.origin + '/complete?' + window.location.href.split('?')[1],
      //         cancel_url: window.location.origin + '/?' + window.location.href.split('?')[1],
      //         userId: setting?setting.userId:"",
      //         appName: "sok",
      //         payType: "onetime",
      //         payStatus: "unpaid"
      //       });

      //   if(userData&&userData.length){

      //     return props.history.push('/payment?' + window.location.href.split('?')[1]);
      //   }else{
      //         if(orderType.toLowerCase()==="pick up"){
      //           setIsPickUp(true);
      //         }
              
      //   }
      // }

  };

  const handleSubmit =()=>{
let items = [];
  let addonsItems = [];
  console.log(order);
  if (order.orderItems.length) {
    order.orderItems.forEach((o) => {
      items.push({
        currency: getCurrency,
        inventory_id: o._id,
        amount: o.price * 100 + (o.price * getPercent / 100) * 100,
        quantity: o.quantity
      });
      addonsItems.push(o.addons);
    });
  }
   let metadata = {modifiers:{}};
  items.forEach((item,i) => {
    metadata.modifiers[item.inventory_id] = JSON.stringify(addonsItems[i]);
  });
  if(isPickUp){
    metadata['schedule']=schedule;
  }else{metadata['delivery']= JSON.stringify(loggedUser.user.email);}

 let eComOrdData ={
        items: items,
        metadata:metadata ,
        email: loggedUser.user.email,
        currency: "usd"
      };

    localStorage.setItem('runningOrder',JSON.stringify(eComOrdData));
    //getCheckoutUrl(dispatch,{...eComOrdData})
    setOpenForm(false);
    return props.history.push('/payment?' + window.location.href.split('?')[1])

  }
  
  const handleCancle =()=>{
          setOpenForm(false);
          setIsPickUp(false);
          setIsDeliver(false);
          setAddress("");
          setNumber("");
          setUserName("");
          setSchedule("");
  }
  


  
  return (
    <Box className={[styles.root, styles.navy]}>

<Dialog
          //onClose={ setOpenSign(false);}
          aria-labelledby="max-width-dialog-title"
          open={openForm}
          fullWidth={true}
          // maxWidth="xs"
          maxWidth={state.widthScreen ? 'sm' : 'xs'}
        >
           <DialogTitle className={styles.center}>
            {/* Enter Details */}
            Order Type {order?order.orderType:""}
          </DialogTitle>

          <div style={{height:"auto",padding:"20px"}}>
              <span>
                <label style={{fontWeight: "bold"}} >Schedule <span  style={{color:"red"}}>*</span>  </label>
                <input type='datetime-local'  className='userInput'  onChange={(e)=>setSchedule(e.target.value)} />
                </span>

                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",padding:"25px",marginBottom:"5px"}}>
                  <button className='c_btn'
                   onClick={handleCancle}
                   >Cancel</button>
                  <button className='s_btn' onClick={handleSubmit}>NEXT</button>
                </div>
            
           
          </div>
        </Dialog>
      <Box className={[styles.main, styles.center]}>
        {/* <img src={state.selectedLogo} height='80px' width='100px' /> */}
        <Typography
          className={[styles.center, styles.title3]}
          gutterBottom
          variant="h3"
          component="h3"
          id='title1'

        >
          Select Order type
        </Typography>
        <Box className={styles.cards}>
          <Card className={[styles.card, styles.space]}>
            <CardActionArea onClick={() => selectHandler('Pick Up')}>
              <CardMedia
                component="img"
                alt="Pay here"
                image="/images/pickup.png"
                className={styles.media}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h4"
                  color="textPrimary"
                  component="p"
                >
                  PICK UP
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>



          <Card className={[styles.card, styles.space]}>
            <CardActionArea onClick={() => selectHandler('Delivery')} id='counter'>
              <CardMedia
                component="img"
                alt="At counter"
                image="/images/delivery.png"
                className={styles.media}
                style={{width:"80%",marginLeft:"15px"}}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h4"
                  color="textPrimary"
                  component="p"
                 
                >
                  DELIVERY
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
      </Box>
    </Box>
  );
}
