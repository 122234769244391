import React, { useContext, useEffect, useState } from 'react';
import { NumericKeyboard } from 'react-numeric-keyboard';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import Logo from '../components/Logo';
import { Store } from '../Store';
import { Alert } from '@material-ui/lab';
import {  getCheckoutUrl,getUserData } from '../actions';
import Axios from 'axios';
import HomeScreen from './SelectPaymentScreen';
import config,{ getParameterByName,merchantCode} from '../util';


export default function CompleteOrderScreen(props) {
  const styles = useStyles();
  const { state, dispatch } = useContext(Store);
  const { selectedCurrency, order, newURL } = state;
  const [newOrderProcessing, setNewOrderProcessing]= useState(true);
  const baseURL = config.baseURL;
  
  useEffect(() => {
     let runningOrder = localStorage.getItem('runningOrder');
    if(runningOrder)
    {
      runningOrder = JSON.parse(runningOrder);
      //getCheckoutUrl(dispatch,{...runningOrder});
      getStripeCheckout(runningOrder);
      localStorage.removeItem('runningOrder');
    }

  }, []);

  const getStripeCheckout = async (runningOrder) => {
    try {
      let merchantDtl=JSON.parse(localStorage.getItem('userInfo'));
      let subPath = "/api/clover/ecom/orders?merchantCode=";
      const { data } = await Axios.post(`${baseURL}${subPath}${merchantDtl.merchantCode}`,runningOrder);
      if (data  && data.id) {
        
       setNewOrderProcessing(false);
      }

    }
    catch (error) {
      console.log(error);

    }
  };

  return (
    <Box >
      {newOrderProcessing && <h1 >Placing your order please wait...</h1>}
      {!newOrderProcessing && <h1 style={{color:"green"}}>Hurrah! Your order placed successfully.</h1>}
    </Box>
  );

}
