import React, { useContext, useEffect, useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    CircularProgress,
    Dialog,
    DialogTitle,
  } from '@material-ui/core';
//   import {
//     userSingnUp,
//     userSingnIn
//   } from '../actions';
  import { useStyles } from '../styles';
  import { Store } from '../Store';
import axios from "axios";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import  {USER_SIGNUP,
USER_SIGNUP_SUCCESS,
USER_SIGNUP_FAIL,

USER_SIGNIN,  
USER_SIGNIN_SUCCESS, 
USER_SIGNIN_FAIL,

} from '../constants';
import config,{ getParameterByName,merchantCode,userId} from '../util';

const SignUp =(props)=>{
    const styles = useStyles();
    const { state, dispatch } = useContext(Store);
    const[showPass,setShowPass] = useState(false);
     const [conShowPass,setConShowPass] =useState(false);
     const[pass1,setPass1]=useState(false);
     const[Signin,setSign] =useState(false);

     const[singUpFirstName,setSignUpFirstName]=useState("");
     const[signUpLastName,setSignUpLastName]=useState("")
     const[signUpEmail,setSignUpEmail]=useState("");
     const[singUpNumber,setSignUpNumber]=useState("");
     const[signUpAddress,setSignUpAdress]=useState("");
     const[singUpPassword,setSignUpPassword]=useState("");
     const[singUpConPassword,setSignUpConPassword]=useState("");
     
     const[signInEmail,setSignInEmail]=useState("");
     const[singInPasswprd,setSignInPassword]=useState("");

     let {setting} = state.userSetting;
     let {userInfo}=state.userData;
        console.log(userInfo);

//    const handleSignIN =()=>{
//             if(signInEmail==="demo" && singPasswprd === "1234"){
//                 props.setSignDialog(false);
//             }else{
//                     document.getElementById("error").innerHTML="username and password incorrect!"
//             }
//      }

    
console.log(state.signInData);


 const userSingnUp = async ()=>{

    console.log(singUpFirstName,signUpEmail,signUpAddress,singUpPassword,singUpNumber);
        dispatch({type:USER_SIGNUP});
        if(singUpFirstName&&signUpEmail&&singUpPassword&&singUpNumber){
            console.log("Ravi");
             axios.post(`${config.authapi}/customer/register`,{
                                        firstName: singUpFirstName,
                                        lastName: signUpLastName,
                                        email: signUpEmail,
                                        phone:singUpNumber,
                                        password: singUpPassword,
                                        referenceDetails:"",
                                        userId: userId,
                                        address:signUpAddress,
                                        isEmailVerified: false,
                                        isPhoneVerified: false,
                                        merchantCode: userInfo?userInfo.merchantCode:"",
                                            }).then(res=>{
                                                res.data ? setSign(false) : console.log("Hello") ;
                                                return dispatch({
                                                    type: USER_SIGNUP_SUCCESS,
                                                    payload:res.data,
                                                  });
                                            }).catch(err=>{
                                                return  dispatch({
                                                    type:USER_SIGNUP_FAIL,
                                                    payload:err.message,
                                                  });
                                            });
    }else{
        console.log("hello");
    }
 }

//  email: { type: String,trim:true,sparse: true, unique: true, required: true },
//  phone: { type: String, trim: true, unique: true, sparse: true},
//  firstName: { type: String, required: true },
//  lastName: { type: String },
//  address: { type: String },
//  password: { type: String, required: true },
//  isEmailVerified: {type:Boolean, default: false},
//  isPhoneVerified: {type:Boolean, default: false},
//  createdDate: { type: Date, default: Date.now },
//  referenceDetails:{type:String},
//  userId: {type:String}
      
 const userSingnIn = async ()=>{
              dispatch({type:USER_SIGNIN});
    if(signInEmail&&singInPasswprd){
                console.log(signInEmail,singInPasswprd);
                const email=signInEmail;
                const password =singInPasswprd
              try{
                const {data}= await axios.post(`${config.authapi}/customer/authenticate`, {email,password});
                     data?props.setSignDialog(false):console.log("hello");

                     data?sessionStorage.setItem("customerInfo",JSON.stringify(data)):console.log("hello");
                     
              return dispatch({
                  type: USER_SIGNIN_SUCCESS,
                  payload:data,
                });
              }
              catch(error){
                document.getElementById("error").innerHTML="username and password incorrect!"
              return  dispatch({
                  type:USER_SIGNIN_FAIL,
                  payload:error.message,
                });
      
            }
        }
    
      }
      

    return(
        <Box  className={styles.root} style={{backgroundColor: state.selectedBgColor,height:"auto"}}>
           {Signin&& <div>
                <h2 align='center'>Sign Up</h2>
                <span>
                    <label>First Name <span style={{color:"red"}}>*</span>   </label><br/>
                    <input type='text' className='userInput_log' onChange={(e)=>setSignUpFirstName(e.target.value)} />
                </span><br/>
                <span>
                    <label>Last Name <span style={{color:"red"}}>*</span>   </label><br/>
                    <input type='text' className='userInput_log' onChange={(e)=>setSignUpLastName(e.target.value)} />
                </span><br/>
                <span>
                    <label>Phone No  <span style={{color:"red"}}>*</span>  </label><br/>
                    <input type='text' className='userInput_log' onChange={(e)=>setSignUpNumber(e.target.value)}/>
                </span><br/>

                <span>
                    <label>Address <span style={{color:"red"}}>*</span>  </label><br/>
                    <input type='text'className='userInput_log' onChange={(e)=>setSignUpAdress(e.target.value)} />
                </span><br/>

                <span>
                    <label>Email <span style={{color:"red"}}>*</span>   </label><br/>
                    <input type='email' className='userInput_log' autocomplete="off" placeholder='Email'  onChange={(e)=>setSignUpEmail(e.target.value)} />
                </span><br/>

                <span>
                    <label>Password  <span style={{color:"red"}}>*</span>  </label><br/>
                    <span>
                    <input type='password' id='userPass' autocomplete="off" className='userInput_log' onChange={(e)=>setSignUpPassword(e.target.value)} />
                    <span style={{position:"absolute",right:"68px",cursor: "pointer"}}>
                            {pass1?
                            <VisibilityOffOutlinedIcon onClick={()=>{
                                setPass1(false);
                                document.getElementById("userPass").type="password";
                            }}/>
                            :
                            <VisibilityOutlinedIcon onClick={()=>{
                                setPass1(true);
                                document.getElementById("userPass").type="text";
                            }}/>
                            }
                        </span>
                    </span>
                </span><br/>
                {/* <span>
                    <label>Confrim Password <span style={{color:"red"}}>*</span>   </label><br/>
                    <span>
                    <input type='password' id='conPass' className='userInput_log' style={{marginBottom:"5px"}}  onChange={(e)=>setSignUpConPassword(e.target.value)}/>
                    <span style={{position:"absolute",right:"68px",cursor: "pointer"}}>
                            {conShowPass?
                            <VisibilityOffOutlinedIcon onClick={()=>{
                                setConShowPass(false);
                                document.getElementById("conPass").type="password";
                            }}/>
                            :
                            <VisibilityOutlinedIcon onClick={()=>{
                                setConShowPass(true);
                                document.getElementById("conPass").type="text";
                            }}/>
                            }
                        </span>
                    </span>
                </span>  */}

                    <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",padding:"20px"}}>
                    <button onClick={()=>setSign(false)} className='c_btn'  style={{backgroundColor:"#fff",color:"#0c1c02",fontSize:"18px"}}>Back</button>
                        <button className='s_btn' style={{backgroundColor:"blue"}} onClick={userSingnUp}>Sign Up</button>
                    </div>
            </div>}

            {/* Signin part */}

            {!Signin &&<div>
                 <h2 align='center'>Sign In</h2>
                 <span id='error' style={{color:"red"}}></span><br></br>
                 <span>
                    <label>Email or Phone number <span style={{color:"red"}}>*</span>   </label><br/>
                    <input type='text' className='login_input' autocomplete="off" placeholder='Email or Phone number' onChange={(e)=>setSignInEmail(e.target.value)} />
                </span><br/>

                <span>
                    <label>Password  <span style={{color:"red"}}>*</span>  </label><br/>
                    <span >

                    <input type='password' style={{marginBottom:"8px"}}  autocomplete="off" placeholder='Password' id="pass" className='login_input' onChange={(e)=>setSignInPassword(e.target.value)} />
                        <span style={{position:"absolute",right:"68px",cursor: "pointer"}}>
                            {showPass?
                            <VisibilityOffOutlinedIcon onClick={()=>{
                                setShowPass(false);
                                document.getElementById("pass").type="password";
                            }}/>
                            :
                            <VisibilityOutlinedIcon onClick={()=>{
                                setShowPass(true);
                                document.getElementById("pass").type="text";
                            }}/>
                            }
                        </span>
            
                            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",padding:"5px",width:"95%"}}>
                                    <span style={{cursor:"pointer",color:"blue",fontSize:'15px'}}>ForgotPassword ?</span>
                                    <span>New user? please,
                                        <span onClick={()=>setSign(true)} style={{cursor:"pointer",color:"rgb(13, 150, 155)",fontSize:'18px'}}>Sign Up</span>
                                    </span>
                            </div>
                    </span>
                </span>

                    <div style={{display:"flex",justifyContent:"center",alignItems:"center",padding:"20px"}}>
                        <button onClick={userSingnIn} className='s_btn' style={{backgroundColor:"blue",width:"160px",height:"50px",borderRadius:"50px"}}>Sign In</button>
                    </div>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center",padding:"4px"}}>
                        <span style={{cursor:"pointer"}} onClick={()=>props.handleDialog()}>Guest Account</span>
                    </div>

            </div>}
        </Box>
    )
};

export default SignUp;
