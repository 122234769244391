import React, { useContext, useEffect, useState } from 'react';
import axios from "axios";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';
import { useStyles } from '../styles';
import Logo from '../components/Logo';
import { Button, CircularProgress, Dialog,
  DialogTitle, } from '@material-ui/core';
import { setPaymentType, generateQrCode, getCheckoutUrl,setOrderType,setCustomerId } from '../actions';
import { Store } from '../Store';
import config,{ getParameterByName,merchantCode} from '../util';
export default function HomeScreen(props) {
  const { state, dispatch } = useContext(Store);
  const styles = useStyles();
  const { selectedCurrency, order, qr } = state;

  const [userName,setUserName] = useState("");
  const [number,setNumber] =useState("");
  const [schedule,setSchedule]= useState("");
  const [address,setAddress] =useState("");

  const [openForm,setOpenForm] = useState(false);
  const [openSign,setOpenSign] = useState(false);
  const [isPickUp,setIsPickUp] = useState(false);
  const [isDeliver,setIsDeliver] =useState(false);

  let userData =sessionStorage.getItem("userInfo")?JSON.parse(sessionStorage.getItem("userInfo")):null;

  let {setting} = state.userSetting;
    console.log(setting);
    let {userInfo}=state.userData;
console.log(userInfo);


useEffect(()=>{
  if(userData&&userData.length){
   console.log(userData);
   setCustomerId(dispatch,userData[0]._id)
  }
},[])


    if(setting){
     setTimeout(() => {
      let textcolor = document.getElementById("title1");
      textcolor.style.color = setting.color_primary;
    }, 10);

   }




 let sokURL = window.location.href.indexOf('localhost') > 0 ?'https://sok.menulive.in':window.location.origin;
 const baseURL =config.baseURL;
  const payURL = "https://pay.digitallive24.com";

  const getCurrency = userInfo? userInfo.currency:"";
  const getPercent = setting? setting.taxPercent:"";


  const [qrDetails, setQrDetails] = useState([])

  let getTime = new Date();
  let closeByTime = Math.floor(getTime.getTime() / 1000) + 180;
  console.log(closeByTime);

  // const [item,setItem] =useState([]);
console.log(state.order);
  let items = [];
  if (order) {
    order.orderItems.map((o) => {
      items.push({
        price_data: {
          currency: getCurrency,
          product_data: {
            name: o.name
          },
          unit_amount: o.price * 100 + (o.price * getPercent / 100) * 100
        },
        quantity: o.quantity
      })
    });
  }

  console.log(items);


  const selectHandler = (orderType) => {
    setOrderType(dispatch, orderType);
    console.log(order)
      if (order.totalPrice) {
            getCheckoutUrl(dispatch, {
              items: items,
              success_url: window.location.origin + '/complete?' + window.location.href.split('?')[1],
              cancel_url: window.location.origin + '/?' + window.location.href.split('?')[1],
              userId: setting?setting.userId:"",
              appName: "sok",
              payType: "onetime",
              payStatus: "unpaid"
            });

        if(userData&&userData.length){

          return props.history.push('/payment?' + window.location.href.split('?')[1]);
        }else{
console.log("pickup")
              if(orderType === "Pick Up"){
                setOpenForm(true);
                setIsPickUp(true);
                console.log("hello");
              }
              else{
                setOpenForm(true);
              setIsDeliver(true); 
            }
        }
      }

  };

  const handleSubmit =()=>{
    // setCustomerId(dispatch,userData[0]._id);
    console.log("Submited");
    let orderItem =order.orderItems.map(o=>{
      return{
        name:o.name,
        price:o.price,
        unitQty:o.quantity,
        id:o.id
      }
    })
console.log(orderItem);
let data ={customer:{email: "ravi@gmail.com", firstName: "Ravi", id: "T3NPM7GNH7HST"},
            shoppingCart: {lineItems:orderItem}
}

getCheckoutUrl(dispatch,{...data})

    if(address){
      console.log("order Delvery to", address);

      axios.post(`${config.authapi}/customer/auth-and-register`,{
        email:`${number}@menulive.in` ,
          phone:number ,
          firstName:userName,
          lastName: "",
          address: " ",
          password: number,
          isEmailVerified:false ,
          isPhoneVerified:false ,
          referenceDetails:`Address : ${address}`,
          merchantCode:merchantCode ,
      }).then(res=>{
        setCustomerId(dispatch,res.data.user.id);
        setAddress("");
        setNumber("");
        setUserName("");
        setSchedule("");
        setIsPickUp(false);
        setIsDeliver(false);
        setOpenForm(false);
        return props.history.push('/complete?' + window.location.href.split('?')[1])
      })

     
    }else{
      console.log("order pick");
      axios.post(`${config.authapi}/customer/auth-and-register`,{
        email:`${number}@menulive.in` ,
          phone:number ,
          firstName:userName,
          lastName: "",
          address: " ",
          password: number,
          isEmailVerified:false ,
          isPhoneVerified:false ,
          referenceDetails:`Schedule :${schedule}`,
          merchantCode:merchantCode ,
      }).then(res=>{
        setCustomerId(dispatch,res.data.user.id);
        setAddress("");
        setNumber("");
        setUserName("");
        setSchedule("");
        setOpenForm(false);
        setIsPickUp(false);
        setIsDeliver(false);
        return props.history.push('/complete?' + window.location.href.split('?')[1])
      })
      
    }

    

  }
  
  const handleCancle =()=>{
          setOpenForm(false);
          setIsPickUp(false);
          setIsDeliver(false);
          setAddress("");
          setNumber("");
          setUserName("");
          setSchedule("");
          return props.history.push('/?' + window.location.href.split('?')[1])
  }
  


  
  return (
    <Box className={[styles.root, styles.navy]}>

<Dialog
          //onClose={ setOpenSign(false);}
          aria-labelledby="max-width-dialog-title"
          open={openForm}
          fullWidth={true}
          // maxWidth="xs"
          maxWidth={state.widthScreen ? 'sm' : 'xs'}
        >
           <DialogTitle className={styles.center}>
            {/* Enter Details */}
            Order Type {order?order.orderType:""}
          </DialogTitle>
          <div style={{height:"auto",padding:"20px"}}>
                  <span>
                    <label style={{fontWeight: "bold"}} >Name <span style={{color:"red"}}>*</span>  </label>
                    <input className='userInput' placeholder='Name' type='text' onChange={(e)=>setUserName(e.target.value)} />
                  </span><br/>

                <span>
                <label style={{fontWeight: "bold"}} >Phone No <span  style={{color:"red"}}>*</span> </label>
                <input type='text' className='userInput' placeholder='Mobile No'  onChange={(e)=>setNumber(e.target.value)} />
                </span><br/>

             { (isPickUp&&!isDeliver)&&<span>
                <label style={{fontWeight: "bold"}} >Schedule <span  style={{color:"red"}}>*</span>  </label>
                <input type='datetime-local'  className='userInput'  onChange={(e)=>setSchedule(e.target.value)} />
                </span>}{ (isPickUp&&!isDeliver)&&<br/>}

              { (isDeliver&&!isPickUp) && <span>
                <label style={{fontWeight: "bold"}} >Address <span  style={{color:"red"}}>*</span>  </label>
                <input type='text' className='userInput' placeholder='Address'  onChange={(e)=>setAddress(e.target.value)} />
                </span>}
                
                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",padding:"25px",marginBottom:"5px"}}>
                  <button className='c_btn'
                   onClick={handleCancle}
                   >Cancel</button>
                  <button className='s_btn' onClick={handleSubmit}>Submit</button>
                </div>
            
           
          </div>
        </Dialog>
      <Box className={[styles.main, styles.center]}>
        {/* <img src={state.selectedLogo} height='80px' width='100px' /> */}
        <Typography
          className={[styles.center, styles.title3]}
          gutterBottom
          variant="h3"
          component="h3"
          id='title1'

        >
          Select Order type
        </Typography>
        <Box className={styles.cards}>
          <Card className={[styles.card, styles.space]}>
            <CardActionArea onClick={() => selectHandler('Pick Up')}>
              <CardMedia
                component="img"
                alt="Pay here"
                image="/images/pickup.png"
                className={styles.media}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h4"
                  color="textPrimary"
                  component="p"
                >
                  PICK UP
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>



          <Card className={[styles.card, styles.space]}>
            <CardActionArea onClick={() => selectHandler('Delivery')} id='counter'>
              <CardMedia
                component="img"
                alt="At counter"
                image="/images/delivery.png"
                className={styles.media}
                style={{width:"80%",marginLeft:"15px"}}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h4"
                  color="textPrimary"
                  component="p"
                 
                >
                  DELIVERY
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
      </Box>
    </Box>
  );
}
